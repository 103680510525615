import type { FunctionComponent } from "react"

import { NEW_NAV_V1 } from "@/modules/headerFooterFO/constants"
import { useFooterLinksFetch } from "@/modules/headerFooterFO/Footer/hooks/useFooterLinksFetch"
import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { PREFILTERED_JOB_SEARCH_ENABLED_LOCALES } from "@/modules/jobSearchPrefiltered/constants"
import { useLocale } from "@/modules/locales/useLocale"
import { Link } from "@/modules/routing/components/Link"
import { useFlag } from "@/modules/unleash/hooks/useFlag"

import styles from "./FooterMenuList.module.css"

export const FooterMenuListStudent: FunctionComponent = () => {
  const { dataFooterLinks } = useFooterLinksFetch()
  const { t } = useTranslation(["module_footer_fo"])
  const locale = useLocale()

  const isNewNavEnabled = useFlag(NEW_NAV_V1)

  return (
    <>
      <p className={styles.title}>{t("module_footer_fo.students")}</p>
      <ul className={styles.main}>
        <li key={`/${locale}/profiles/new`} className={styles.item}>
          <a className={styles.link} href={`/${locale}/profiles/new`}>
            {t("module_footer_fo.sign_up")}
          </a>
        </li>
        <li key={`/${locale}/job-offers`} className={styles.item}>
          <Link className={styles.link} href={`/${locale}/job-offers`}>
            {t("module_footer_fo.search_jobs")}
          </Link>
        </li>
        <li key={`/${locale}/companies`} className={styles.item}>
          <Link className={styles.link} href={`/${locale}/companies`}>
            {t("module_footer_fo.discover_companies")}
          </Link>
        </li>
        <li key={`/${locale}/events`} className={styles.item}>
          <Link className={styles.link} href={`/${locale}/events`}>
            {t("module_footer_fo.recruiting_events")}
          </Link>
        </li>
        <li key={`/${locale}/advices`} className={styles.item}>
          <a className={styles.link} href={`/${locale}/advices`}>
            {t("module_footer_fo.recruiting_advice")}
          </a>
        </li>
        {isNewNavEnabled && (
          <li key={`/${locale}/job-roles`} className={styles.item}>
            <Link className={styles.link} href={`/${locale}/job-roles`}>
              {t("module_footer_fo.job_roles")}
            </Link>
          </li>
        )}
        {dataFooterLinks.map(({ footerTitle, slug }) => (
          <li key={footerTitle} className={styles.item}>
            <Link className={styles.link} href={`/${locale}/info/${slug}`}>
              {footerTitle}
            </Link>
          </li>
        ))}
        {PREFILTERED_JOB_SEARCH_ENABLED_LOCALES.includes(locale) && (
          <li className={styles.item}>
            <Link className={styles.link} href={`/${locale}/job-search/list`}>
              {t("module_footer_fo.job_search_prefiltered.index_link_title")}
            </Link>
          </li>
        )}
      </ul>
    </>
  )
}
